<template>
  <v-app :style="{ background: menuThemeBackground }">
    <div v-if="isMaintenanceMode">
      <maintenance />
    </div>
    <div v-else class="app-wrapper">
      <d-tour />
      <left-drawer
        @set-content="setSubDrawerContent"
        @set-sub-menu-name="setSubDrawerName"
      />
      <sub-drawer :children="subDrawerContent" :name="subDrawerName" />
      <top-bar />
      <loader v-if="isLoading" />
      <v-main v-else>
        <router-view></router-view>
      </v-main>
      <modal-first-login
        first-login
        :dialog="dialog"
        @close="closeModalFirstLogin"
      />
      <modal-job-title :dialog="hasjobTitle" @close="fetchUserData" />
      <plan-presentation-modal
        :dialog="modalPlanDialog"
        :images="imagesPlan"
        :title-modal="titlePlanModal"
        @close="closeModal"
      />
      <loader-overlay />
      <alert-principal />
      <div class="warnings">
        <warning-area-exceeded-account v-if="areaExceededAccount" />
        <warning-farm-disabled v-if="currentFarm && !currentFarm.enabled" />
      </div>
      <float-button v-show="!isLoading"></float-button>
    </div>
  </v-app>
</template>

<script>
import {
  initialize,
  logEvent,
  events,
  identifyUser,
} from '@/services/analytics'
import Maintenance from '@/pages/maintenance'
import LoaderOverlay from '@/components/LoaderOverlay.vue'
import AlertPrincipal from '@/components/AlertPrincipal.vue'
import ModalFirstLogin from '@/modules/menu/components/ModalSenha.vue'
import ModalJobTitle from '@/modules/menu/components/ModalJobTitle.vue'
import Cookies from 'js-cookie'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import LayoutMixin from '@/components/LayoutMixin.vue'
import seasonsCropsStorage from './services/storage/seasonsCropsStorage'
import Hotjar from 'vue-hotjar'
import Vue from 'vue'
import Loader from '@/components/Loaders/Loader.vue'
import PlanPresentationModal from '@/components/Dialogs/PlanPresentationModal.vue'
import menuImagesPlan from '@/utils/imagesPlan/menuImagesPlan.js'
import TopBar from '@/modules/menu/views/TopBar.vue'
import DTour from '@/components/DTour'
import LeftDrawer from '@/modules/menu/views/LeftDrawer.vue'
import SubDrawer from '@/modules/menu/components/SubDrawer.vue'
import WarningAreaExceededAccount from '@/components/Warnings/WarningAreaExceededAccount.vue'
import WarningFarmDisabled from '@/components/Warnings/WarningFarmDisabled.vue'
import FloatButton from '@/components/FloatButton.vue'

export default {
  name: 'App',

  mixins: [LayoutMixin],

  components: {
    LoaderOverlay,
    AlertPrincipal,
    ModalFirstLogin,
    ModalJobTitle,
    PlanPresentationModal,
    Maintenance,
    Loader,
    TopBar,
    LeftDrawer,
    DTour,
    SubDrawer,
    WarningAreaExceededAccount,
    WarningFarmDisabled,
    FloatButton,
  },

  data() {
    return {
      subDrawerContent: [],
      subDrawerName: '',
      isLoading: false,
      modalPlanDialog: false,
      titlePlanModal: '',
      imagesPlan: [],
      dialog: false,
    }
  },

  async mounted() {
    Cookies.remove('msg-laravel')
    Cookies.remove('status-laravel')
  },

  async created() {
    this.isLoading = true
    await this.changeLanguage(localStorage.getItem('language'))
    await this.fetchUserData()
    await this.redirectToRepasse()

    await this.homePage()
    await this.fetchCrops()
    await this.fetchSeasonsCrops(this.currentFarmId)
    await this.loadCurrentCropByStorage()
    await this.loadSeasonCrops()
    await this.fetchPermissions()

    if (this.isFreemiumPlan) {
      try {
        await this.fetchFreemiumLimit()
      } catch (error) {
        console.error(error)
      }
    }

    await Promise.all([this.loadSeasonCrops()])

    this.isLoading = false

    initialize(this.currentUser?.id)

    this.configOneSignal()

    if (Cookies.get('login-laravel')) {
      Cookies.remove('login-laravel')

      identifyUser({
        profile_id: this.currentUser?.user_profile?.id,
        profile_name: this.currentUser?.user_profile?.name,
        account_type_id: this.currentUser?.account?.account_type_id,
        account_type_name: this.currentUser?.account?.account_type_name,
        job_title_id: this.currentUser?.job_title?.id,
        job_title_name: this.currentUser?.job_title?.name,
        user_plan: this.currentUser?.plan?.name,
        screen_size: this.$vuetify.breakpoint.name,
      })

      logEvent(events.user.login, this.currentUser?.account)
    }

    if (
      process.env.VUE_APP_HOTJAR_ENABLE == 'true' &&
      process.env.VUE_APP_HOTJAR_ID
    ) {
      Vue.use(Hotjar, {
        id: process.env.VUE_APP_HOTJAR_ID,
        isProduction: process.env.VUE_APP_HOTJAR_ENABLE == 'true',
      })
    }

    this.$root.$on('catalogPlan', (params) => {
      this.titlePlanModal = this.$t(`plan.menu.${params.menu}`)
      this.modalPlanDialog = params.modal
      this.getImages(params.menu)
    })
    this.dialog = this.currentUser?.first_login === 'Y'
  },

  computed: {
    ...mapGetters('apiManager', ['isMaintenanceMode']),
    ...mapGetters('user', [
      'currentUser',
      'currentLanguage',
      'isFreemiumPlan',
      'isPlanLevelOne',
      'areaExceededAccount',
    ]),
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('permissions', ['hasPermission']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    ...mapGetters('layout', ['isSubDrawerOpened']),
    ...mapGetters('farms', ['currentFarm']),
    hasjobTitle() {
      return this.currentUser?.job_title === null
    },
    menuThemeBackground() {
      const grey = '#F8F9F8'
      return grey
    },
  },

  watch: {
    currentUser(newUser) {
      if (window.Conpass && newUser?.account?.name && newUser?.email) {
        window.Conpass.init({
          name: newUser.account?.name,
          email: newUser.email,
        })
      }

      if (this.$hj) {
        // FIXME: while we don't get Hotjar's business plan, it'll allows to identify users for NPS survey
        this.$router
          .replace({
            path: this.$route.path,
            query: { ...this.$route.query, u: newUser.id },
          })
          // FIXME: this avoid VueRouter to throw exception because we're replacing to same path
          .catch(() => {})
      }
    },

    currentLanguage() {
      this.$root.$i18n.locale = localStorage.getItem('language')
    },
  },

  methods: {
    ...mapMutations('seasonsCrops', ['setCurrentSeasonCrop']),

    ...mapActions('farms', ['firstFarmLoad']),
    ...mapActions('user', ['fetchUserData', 'changeLanguage']),
    ...mapActions('permissions', ['fetchPermissions']),
    ...mapActions('freemium', ['fetchFreemiumLimit']),
    ...mapActions('seasonsCrops', ['fetchSeasonsCrops']),
    ...mapActions('crops', ['fetchCrops', 'loadCurrentCropByStorage']),

    // FIX: It should be deleted when refactoring repasse
    redirectToRepasse() {
      const PROFILE_ADMIN_CORP_ID = 9
      if (this.currentUser.user_profile.id == PROFILE_ADMIN_CORP_ID) {
        window.location.href = '/repasse'
      }
    },
    async homePage() {
      await this.firstFarmLoad(this.$route?.query?.fazenda_id)

      return this.$router.replace({
        query: { ...this.$route.query, fazenda_id: this.currentFarm?.id },
      })
    },
    configOneSignal() {
      this.$OneSignal.showSlidedownPrompt()
      this.$OneSignal.sendTag('environment', process.env.NODE_ENV)
      this.$OneSignal.sendTag('account', this.currentUser.account.id)
      this.$OneSignal.sendTag('user_id', this.currentUser.id)
      this.$OneSignal.setExternalUserId('' + this.currentUser.id)
    },
    async loadSeasonCrops() {
      const storedSeasonCrop = seasonsCropsStorage()
        .crop(this.currentCrop?.id)
        .get()

      await this.setCurrentSeasonCrop(storedSeasonCrop ?? this.seasonsCrops[0])
    },
    closeModal() {
      this.modalPlanDialog = false
    },
    getImages(path) {
      this.imagesPlan = this.$vuetify.breakpoint.mobile
        ? menuImagesPlan[`mobile_${path}`]
        : menuImagesPlan[path]
    },
    setSubDrawerContent(value) {
      this.subDrawerContent = value
    },
    setSubDrawerName(value) {
      this.subDrawerName = value
    },
    closeModalFirstLogin() {
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  min-height: 100vh;
}

.v-main {
  min-height: 100%;
  background-color: #ffffff;
}

.warnings {
  position: absolute;
  bottom: 2px;
  right: 0px;
  width: calc(100% - 56px);
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media (max-width: 947px) {
    width: 100%;
  }
}
</style>
